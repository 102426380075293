<template>
  <div class="website flex-center">
    <img src="../../assets/forget/21.png" class="el-website-img" alt="">
  </div>
</template>

<script>
export default {
  components: {},

  data () {
    return {
    };
  },

  created(){},

  mounted(){},

  methods: {}
}

</script>
<style lang='less' scoped>
.website{
    width: 100%;
    height: 100vh;
    .el-website-img{
       width: 100%;
       height: 100%;
    }
}
</style>